<template>
    <section id="wrapper" class="error-page" style="height:100vh; ">
        <div class="error-box" style="background: url(https://app.salonfaster.com/assets/images/background/error-bg.jpg) no-repeat center center #fff; position:static;">
            <div class="error-body text-center">
                <h1>404</h1>
                <h3 class="text-uppercase">Sayfa Bulunamadı !</h3>
                <p class="text-muted m-t-30 m-b-30">Aradığın sayfa kaldırılmış olabilir</p>
                <!-- <a href="#" class="btn btn-info btn-rounded waves-effect waves-light m-b-40">Geri dön</a>  --></div>
            <footer class="footer text-center">© {{ new Date().getFullYear() }} <router-link to="https://salonfaster.com">SalonFaster.com</router-link></footer>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>