<template>
    <div id="preloader" class="preloader" >
            <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" /> </svg>
       
        <sync-loader v-if="type == 'sync'" :loading="loading" size="50px" color="#007bff" class="m-t-40 m-b-40" style="display: flex; justify-content: center;"></sync-loader>
        <dot-loader v-if="type == 'dot'" :loading="loading" size="50px" color="#007bff" class="m-t-40 m-b-40" style="display: flex; justify-content: center;"></dot-loader>
    </div>
</template>

<script>
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
import DotLoader from 'vue-spinner/src/DotLoader.vue'
export default {
    name: 'Spinner',
    props: ['loading', 'type'],
    components: {
        SyncLoader,
        DotLoader
    },
    data() {
        return {
            text: ""
        }
    },
    created() {

    }
}
</script>

<style scoped>
.preloader{
    left: 0;
}
</style>