import Vue from 'vue';
import VueRouter from 'vue-router';
import CommentView from '../components/CommentView'
import NotFoundPage from '../components/NotFoundPage'

Vue.use(VueRouter)


const routes = [
    {
    path: '/:code',
    name: 'Yorumla',
    component: CommentView
    },
    {
    path: '/404',
    name: '404',
    component: NotFoundPage
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router