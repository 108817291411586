<template>
    <div style="width:100%; justify-content: center; display:flex;">
        <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                        <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                    </symbol>
                    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                        <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                    </symbol>
                </svg>
    
        <ul class="feedback">
            <li class="angry shadow" :class="selected == 'angry' ? 'active':''" @click="selectEmoji('angry')">
                <div>
                    <svg class="eye left">
                                <use xlink:href="#eye" />
                            </svg>
                    <svg class="eye right">
                                <use xlink:href="#eye"/>
                            </svg>
                    <svg class="mouth">
                                <use xlink:href="#mouth"/>
                            </svg>
                </div>
            </li>
            <li class="sad shadow" :class="selected == 'sad' ? 'active':''" @click="selectEmoji('sad')">
                <div>
                    <svg class="eye left">
                                <use xlink:href="#eye"/>
                            </svg>
                    <svg class="eye right">
                                <use xlink:href="#eye"/>
                            </svg>
                    <svg class="mouth">
                                <use xlink:href="#mouth"/>
                            </svg>
                </div>
            </li>
            <li class="ok shadow" :class="selected == 'ok' ? 'active':''" @click="selectEmoji('ok')">
                <div></div>
            </li>
            <li class="good shadow" :class="selected == 'good' ? 'active':''" @click="selectEmoji('good')">
                <div>
                    <svg class="eye left">
                                <use xlink:href="#eye"/>
                            </svg>
                    <svg class="eye right">
                                <use xlink:href="#eye"/>
                            </svg>
                    <svg class="mouth">
                                <use xlink:href="#mouth"/>
                            </svg>
                </div>
            </li>
            <li class="happy shadow" :class="selected == 'happy' ? 'active':''" @click="selectEmoji('happy')">
                <div>
                    <svg class="eye left">
                                <use xlink:href="#eye"/>
                            </svg>
                    <svg class="eye right">
                                <use xlink:href="#eye"/>
                            </svg>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import EventBus from '../store/EventBus'
export default {
    name: "Emoji",
    props: ['id'],
    data() {
        return {
            selected: '',
            types: [
                '0',
                'angry',
                'sad',
                'ok',
                'good',
                'happy',
            ]
        }
    },
    methods: {
        selectEmoji(type) {
            this.selected = type
            EventBus.$emit('emojiSelected', this.id, this.types.findIndex(s => s == type))
        },
    }
}
</script>

<style scoped>


.feedback {
    --normal: #ECEAF3;
    --normal-shadow: #D9D8E3;
    --normal-mouth: #9795A4;
    --normal-eye: #595861;
    --active: #F8DA69;
    --shadow: #F4B555;
    --active-mouth: #F05136;
    --active-eye: #313036;
    --active-tear: #76b5e7;
    --shadow-angry: #e94f1d;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

.feedback li {
    position: relative;
    border-radius: 50%;
    background: var(--sb, var(--normal));
    box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
    transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
    -webkit-tap-highlight-color: transparent;
}

.feedback li:not(:last-child) {
    margin-right: 2rem;
}

@media screen and (min-width:900px) {
    .feedback li:not(:last-child) {
        margin-right: 5rem;
    }
}

@media screen and (max-width:400px) {
    .feedback li:not(:last-child) {
        margin-right: 1.5rem;
    }
}

.feedback li div {
    width: 40px;
    height: 40px;
    position: relative;
    transform: perspective(240px) translateZ(4px);
}

.feedback li div svg,
.feedback li div:before,
.feedback li div:after {
    display: block;
    position: absolute;
    left: var(--l, 9px);
    top: var(--t, 13px);
    width: var(--w, 8px);
    height: var(--h, 2px);
    transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
}

.feedback li div svg {
    fill: none;
    stroke: var(--s);
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke 0.4s;
}

.feedback li div svg.eye {
    --s: var(--e, var(--normal-eye));
    --t: 17px;
    --w: 7px;
    --h: 4px;
}

.feedback li div svg.eye.right {
    --l: 23px;
}

.feedback li div svg.mouth {
    --s: var(--m, var(--normal-mouth));
    --l: 11px;
    --t: 23px;
    --w: 18px;
    --h: 7px;
}

.feedback li div:before,
.feedback li div:after {
    content: "";
    z-index: var(--zi, 1);
    border-radius: var(--br, 1px);
    background: var(--b, var(--e, var(--normal-eye)));
    transition: background 0.4s;
}

.feedback li.angry {
    --step-1-rx: -24deg;
    --step-1-ry: 20deg;
    --step-2-rx: -24deg;
    --step-2-ry: -20deg;
}

.feedback li.angry div:before {
    --r: 20deg;
}

.feedback li.angry div:after {
    --l: 23px;
    --r: -20deg;
}

.feedback li.angry div svg.eye {
    stroke-dasharray: 4.55;
    stroke-dashoffset: 8.15;
}

.feedback li.angry.active {
    -webkit-animation: angry 1s linear;
    animation: angry 1s linear;
}

.feedback li.angry.active div:before {
    --middle-y: -2px;
    --middle-r: 22deg;
    -webkit-animation: toggle 0.8s linear forwards;
    animation: toggle 0.8s linear forwards;
}

.feedback li.angry.active div:after {
    --middle-y: 1px;
    --middle-r: -18deg;
    -webkit-animation: toggle 0.8s linear forwards;
    animation: toggle 0.8s linear forwards;
}

.feedback li.sad {
    --step-1-rx: 20deg;
    --step-1-ry: -12deg;
    --step-2-rx: -18deg;
    --step-2-ry: 14deg;
}

.feedback li.sad div:before,
.feedback li.sad div:after {
    --b: var(--active-tear);
    --sc: 0;
    --w: 5px;
    --h: 5px;
    --t: 15px;
    --br: 50%;
}

.feedback li.sad div:after {
    --l: 25px;
}

.feedback li.sad div svg.eye {
    --t: 16px;
}

.feedback li.sad div svg.mouth {
    --t: 24px;
    stroke-dasharray: 9.5;
    stroke-dashoffset: 33.25;
}

.feedback li.sad.active div:before,
.feedback li.sad.active div:after {
    -webkit-animation: tear 0.6s linear forwards;
    animation: tear 0.6s linear forwards;
}

.feedback li.ok {
    --step-1-rx: 4deg;
    --step-1-ry: -22deg;
    --step-1-rz: 6deg;
    --step-2-rx: 4deg;
    --step-2-ry: 22deg;
    --step-2-rz: -6deg;
}

.feedback li.ok div:before {
    --l: 12px;
    --t: 17px;
    --h: 4px;
    --w: 4px;
    --br: 50%;
    box-shadow: 12px 0 0 var(--e, var(--normal-eye));
}

.feedback li.ok div:after {
    --l: 13px;
    --t: 26px;
    --w: 14px;
    --h: 2px;
    --br: 1px;
    --b: var(--m, var(--normal-mouth));
}

.feedback li.ok.active div:before {
    --middle-s-y: .35;
    -webkit-animation: toggle 0.2s linear forwards;
    animation: toggle 0.2s linear forwards;
}

.feedback li.ok.active div:after {
    --middle-s-x: .5;
    -webkit-animation: toggle 0.7s linear forwards;
    animation: toggle 0.7s linear forwards;
}

.feedback li.good {
    --step-1-rx: -14deg;
    --step-1-rz: 10deg;
    --step-2-rx: 10deg;
    --step-2-rz: -8deg;
}

.feedback li.good div:before {
    --b: var(--m, var(--normal-mouth));
    --w: 5px;
    --h: 5px;
    --br: 50%;
    --t: 22px;
    --zi: 0;
    opacity: 0.5;
    box-shadow: 16px 0 0 var(--b);
    filter: blur(2px);
}

.feedback li.good div:after {
    --sc: 0;
}

.feedback li.good div svg.eye {
    --t: 15px;
    --sc: -1;
    stroke-dasharray: 4.55;
    stroke-dashoffset: 8.15;
}

.feedback li.good div svg.mouth {
    --t: 22px;
    --sc: -1;
    stroke-dasharray: 13.3;
    stroke-dashoffset: 23.75;
}

.feedback li.good.active div svg.mouth {
    --middle-y: 1px;
    --middle-s: -1;
    -webkit-animation: toggle 0.8s linear forwards;
    animation: toggle 0.8s linear forwards;
}

.feedback li.happy div {
    --step-1-rx: 18deg;
    --step-1-ry: 24deg;
    --step-2-rx: 18deg;
    --step-2-ry: -24deg;
}

.feedback li.happy div:before {
    --sc: 0;
}

.feedback li.happy div:after {
    --b: var(--m, var(--normal-mouth));
    --l: 11px;
    --t: 23px;
    --w: 18px;
    --h: 8px;
    --br: 0 0 8px 8px;
}

.feedback li.happy div svg.eye {
    --t: 14px;
    --sc: -1;
}

.feedback li.happy.active div:after {
    --middle-s-x: .95;
    --middle-s-y: .75;
    -webkit-animation: toggle 0.8s linear forwards;
    animation: toggle 0.8s linear forwards;
}

.feedback li:not(.active) {
    cursor: pointer;
}

.feedback li:not(.active):active {
    transform: scale(0.925);
}

.feedback li.active {
    --sb: var(--active);
    --sh: var(--shadow);
    --m: var(--active-mouth);
    --e: var(--active-eye);
}

.feedback li.active div {
    -webkit-animation: shake 0.8s linear forwards;
    animation: shake 0.8s linear forwards;
}

@-webkit-keyframes shake {
    30% {
        transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
    }
    60% {
        transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
    }
    100% {
        transform: perspective(240px) translateZ(4px);
    }
}

@keyframes shake {
    30% {
        transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
    }
    60% {
        transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
    }
    100% {
        transform: perspective(240px) translateZ(4px);
    }
}

@-webkit-keyframes tear {
    0% {
        opacity: 0;
        transform: translateY(-2px) scale(0) translateZ(0);
    }
    50% {
        transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
    }
    20%,
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
    }
}

@keyframes tear {
    0% {
        opacity: 0;
        transform: translateY(-2px) scale(0) translateZ(0);
    }
    50% {
        transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
    }
    20%,
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
    }
}

@-webkit-keyframes toggle {
    50% {
        transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
    }
}

@keyframes toggle {
    50% {
        transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
    }
}

@-webkit-keyframes angry {
    40% {
        background: var(--active);
    }
    45% {
        box-shadow: inset 3px -3px 4px var(--shadow), inset 0 8px 10px var(--shadow-angry);
    }
}

@keyframes angry {
    40% {
        background: var(--active);
    }
    45% {
        box-shadow: inset 3px -3px 4px var(--shadow), inset 0 8px 10px var(--shadow-angry);
    }
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: inherit;
}

*:before,
*:after {
    box-sizing: inherit;
}

body {
    min-height: 100vh;
    display: flex;
    font-family: "Roboto", Arial;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #F9F9FC;
}

body .dribbble {
    position: fixed;
    display: block;
    right: 20px;
    bottom: 20px;
}

body .dribbble img {
    display: block;
    height: 28px;
}

body .twitter {
    position: fixed;
    display: block;
    right: 64px;
    bottom: 14px;
}

body .twitter svg {
    width: 32px;
    height: 32px;
    fill: #1da1f2;
}
</style>