<template>
    <div id="app" class="container" :style="showNotFound || error ? 'height: 100vh;':''">
       <spinner v-if="loading" :loading="loading" :type="type" />
        <transition name="slide-fade">
            <comment-view v-show="!showNotFound && !loading" :comment="comment" />
        </transition>
        <transition name="slide-fade">
            <not-found-page v-show="showNotFound && !loading" />
        </transition>
    </div>
</template>

<script>
import CommentView from './components/CommentView.vue'
import NotFoundPage from './components/NotFoundPage'
import Spinner from './components/Spinner.vue'
import EventBus from './store/EventBus'
export default {
    components: { CommentView, NotFoundPage,Spinner, },
    name: 'App',
    data() {
        return {
            showNotFound: false,
            error: false,
            loading: true,
            type: 'dodt',
            comment: ""
        }
    },
    destroyed(){
        EventBus.$off('error')
    },
    created() {
        this.getCommentData(this.$route.params.code)

        EventBus.$on('error', () => {
            this.error = true
        })
    },
    methods: {
        getCommentData(commentCode) {
          this.loading = true
            var bodyFormData = new FormData();
            bodyFormData.append('islemid', 1);
            bodyFormData.append('yorumkod', commentCode);
            this.axios({
                    method: "post",
                    url: 'https://app.salonfaster.com/php/yorum.php',
                    data: bodyFormData,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                .then((response) => {
                    if (response.data == "") {
                        /* this.$router.push('/404') */
                        this.showNotFound = true
                    } else {
                        this.comment = response.data[0]
                        this.showNotFound = false
                        window.document.title = this.comment.kurumad + " Değerlendirme";
                    }
                    this.loading = false
                })
                .catch(function(response) {
                    console.log(response);
                });
        }
    },
}
</script>

<style>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 1s;
}

.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
}

.slide-slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-slide-fade-enter, .slide-slide-fade-leave-to{
  transform: translateX(10px);
  opacity: 0;
}

* {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

.page-titles {
    box-shadow: none;
}

.emojis {
    cursor: pointer;
    filter: grayscale(100%);
    transition: .2s ease;
    width: 2.6rem;
}

.emojis.active {
    filter: none;
    box-shadow: 0 0 0 1rem #EBEBF3;
    border-radius: 50px;
    width: 3rem;
}

textarea,
.form-control {
    border: 2px solid rgba(56, 91, 184, 0.8);
    -webkit-user-select: auto;
}

.form-control:focus {
    border: 2px solid rgba(56, 91, 184, 0.8);
    box-shadow: 0 0 0 .2rem rgba(56, 91, 184, 0.5);
}

.btn-glow {
    border-radius: 10px;
    background-color: #385BB8;
    color: white;
    height: 50px;
    box-shadow: 0px 5px 10px rgba(56, 91, 184, 0.4), 0px 15px 25px rgba(56, 91, 184, 0.4), 0px 5px 10px rgba(56, 91, 184, 0.4);
    /* background-image: linear-gradient(-90deg, #CF77F3 0%, #009BFF 47%, #2AC9DB 100%); */
}

.btn-glow:focus {
    box-shadow: 0px 2px 10px rgba(56, 91, 184, 0.4), 0px 2px 10px rgba(56, 91, 184, 0.4), 0px 2px 10px rgba(56, 91, 184, 0.4);
    color: white;
}

.card {
    border-radius: 20px;
    padding: 0px 20px 0px 20px;
    margin: 20px 0px 20px 0px;
}

.btn-close {
    background-color: #EBEBF3;
}

.emoji-row {
    justify-content: end;
}

.page-titles {
    margin-top: 0px;
}

@media screen and (max-width: 1080px) {
    .emoji-row {
        justify-content: space-between;
    }
    .page-titles {
        margin-top: 40px;
    }
}

.social-links>a {
    color: inherit;
}

.card {
    transition: .5s ease-in-out;
}

.hidden-card {
    transition: .5s ease-in-out;
    overflow: hidden;
    zoom: 0;
    /* Old IE only */
    -moz-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: top;
}
</style>
