<template>
    <div class="row">
        <div class="col-12">
            <div class="card" :class="hideCard ? 'hidden-card':''">
                <div class="card-actions m-t-20" style="position: absolute; right: 0; margin-right: 20px; z-index: 99;">
                    <a @click="closeCard()" class="btn btn-close btn-circle" style="padding:10px; float: right;" data-action="close"><i class="ti-close"></i></a>
                </div>
                <div class="card-body">
                    <div class="row" style="margin-top:60px;">
                        <transition type="fade">
                            <div v-if="!feedbackSending && feedbackClicked && !error" class="col-12 " style="text-align:center;">
                                <h1 class="" style="text-align:center;">Geri bildirimin için teşekkür eder </h1>
                                <h3>{{ getNightMessage('text') }}</h3>
                            </div>
                        </transition>
                        <transition type="fade">
                            <div v-if="error" class="col-12 " style="text-align:center;">
                                <h3>Nadiren sorun oluşabiliyor. Sayfayı yenileyip deneyebilirsin</h3>
                            </div>
                        </transition>
                    </div>
                    <transition type="fade">
                        <div class="form-body" :class="!feedbackSending && feedbackClicked ? 'hidden':''">
                            <div class="row m-t-10">
                                <div class="col-12" style=" padding: 0;">
                                    <h2>{{ getNightMessage('title') }}, <br><b>{{ comment.musteriisim }}</b></h2>
                                    <h4 style="margin-top:2rem; font-weight: 300;"><b>{{ comment.randevutarihsaat }}</b> tarihinde <b>{{ comment.hizmetad }}</b> randevunuzdaki hizmetimizden memnun kaldınız mı merak ediyoruz.
                                    </h4>
                                </div>
                            </div>
                            <div class="row p-0 m-t-20">
                                <div class="col-12 p-0" style="text-align:left;">
                                    <h4 style="font-weight: 300;"><b>Personelin hizmeti:</b> </h4>
                                </div>
                            </div>
                            <div class="row m-t-20 m-b-20 emoji-row">
                                <emoji :id="'personelPuan'" />
                            </div>
                            <div class="row p-0">
                                <div class="col-12 p-0  m-t-10" style="text-align:left;">
                                    <h4 style="font-weight: 300;"><b>Kurumun hizmeti:</b> </h4>
                                </div>
                            </div>
                            <div class="row m-t-20 m-b-20 emoji-row">
                                <emoji :id="'kurumPuan'" />
                            </div>
                            <div class="row m-t-40">
                                <div class="col-12" style=" padding: 0;">
                                    <h4 style="font-weight: 300;">Deneyiminizi bizimle paylaşır mısınız?</h4>
                                    <div class="input-group">
                                        <textarea rows="5" style="color:inherit;" class="form-control" v-model="feedbackMessage"></textarea>
                                    </div>
                                    <h4 style="font-weight: 300;"><small>Teşekkür ederiz, <b>{{ comment.kurumad }}</b></small></h4>
                                </div>
                            </div>
                        </div>
                    </transition>
                    <div class="row">
                        <button :disabled="!feedbackSending && feedbackClicked ? true : false" class="btn btn-block btn-glow m-t-30 m-b-20" :class="error ? 'btn-danger': (feedbackClicked && !feedbackSending && !error) ? 'btn-active': ''" :style="(feedbackClicked && !feedbackSending && !error)?'background-color:var(--green);': feedbackSending ? 'height:70px;' :''"
                            @click="saveComment()">
                                        <div v-if="feedbackSending"  class='loader'>
                                            <div class="bg"></div>
                                            <div class='circle'></div>
                                            <div class='circle'></div>
                                            <div class='circle'></div>
                                            <div class='circle'></div>
                                            <div class='circle'></div>
                                        </div>
                                        <span v-if="!feedbackSending && !feedbackClicked" >Gönder <i class="fa fa-send m-l-10"></i></span>
                                        <span v-if="!feedbackSending && feedbackClicked && !error" >Gönderildi <i class="fa fa-check m-l-10"></i></span>
                                        <span v-if="error" >Gönderilemedi <i class="fa fa-close m-l-10"></i></span>
                                </button>
                    </div>
                    <div v-if="!error" class="row m-b-40 m-t-40">
                        <div class="col-12 m-b-30">
                            <hr>
                        </div>
                        <div class="col-12">
                            <h5 style="text-align: center;"> - Bizi takip edin -</h5>
                            <div class="row m-t-30" style="justify-content: space-evenly;">
                                <div class="col-2 social-links" style="padding: 0; text-align:center;" v-for="item in links" :key="item.url">
                                    <a :href="item.url"><i class="fa" :class="'fa-' + item.img" style="font-size: 1.5rem;"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 m-t-40">
                            <p style="text-align: center;"><b style="font-weight: 700;">{{ comment.kurumad }}</b></p>
                            <p style="text-align: center;"><small><!-- {{ companyPhone }} - < --><u>{{ comment.kurumadres }} <br> {{ comment.kurumil + '/' + comment.kurumilce }} </u></small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Emoji from './Emoji.vue'
import EventBus from '../store/EventBus'
export default {
    components: { Emoji },
    name: 'HelloWorld',
    props: ['comment'],
    data() {
        return {
            error: false,
            showNotFound: false,
            hideCard: false,
            personelPuan: "",
            kurumPuan: "",
            links: [
                { url: 'http://facebook.com', img: 'facebook' },
                { url: 'http://instagram.com', img: 'instagram' },
                { url: 'http://twitter.com', img: 'twitter' },
                { url: 'http://youtube.com', img: 'youtube' },
            ],
            companyImg: "logo3.jpeg",
            feedbackMessage: "",
            selected: '',
            feedbackClicked: false,
            feedbackSending: false
        }
    },
    created() {
        EventBus.$on('emojiSelected', (type, emoji) => {
            if (type == "kurumPuan") {
                this.kurumPuan = emoji
            } else {
                this.personelPuan = emoji
            }
        })
    },
    methods: {
        getNightMessage(type) {
            let hour = new Date().getHours()
            if (type == 'title') {
                return (hour >= 6 && hour <= 12) ? 'Günaydın' : (hour >= 13 && hour <= 17) ? 'Merhaba' : (hour >= 18 && hour <= 21) ? 'İyi Akşamlar' : 'İyi Geceler'
            } else {
                let text = (hour >= 6 && hour <= 17) ? 'günler' : (hour >= 18 && hour <= 21) ? 'akşamlar' : 'geceler'
                return `İyi ${text} dileriz`
            }
        },
        saveComment() {
            this.feedbackSending = true
            this.feedbackClicked = true

            var bodyFormData = new FormData();
            bodyFormData.append('islemid', 2);
            bodyFormData.append('id', this.comment.id);
            bodyFormData.append('kid', this.comment.kid);
            bodyFormData.append('puan', this.personelPuan);
            bodyFormData.append('salonpuan', this.kurumPuan);
            bodyFormData.append('yorum', this.feedbackMessage);
            bodyFormData.append('tarihsaat', new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().slice(0, 19).replace('T', ' '));
            this.axios({
                    method: "post",
                    url: 'https://app.salonfaster.com/php/yorum.php',
                    data: bodyFormData,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                .then((response) => {
                    if (response.data == "Başarılı") {
                        this.feedbackSending = false
                        window.scroll(0, 0)

                        setTimeout(() => {
                            this.$router.push("https://app.salonfaster.com")
                        }, 1500)
                    } else {
                        // Error
                        this.feedbackSending = false
                        this.error = true
                        EventBus.$emit('error')
                    }
                })
                .catch(function(response) {
                    console.log(response);
                });
        },
        update(type) {
            this.selected = type
        },
        emojiClicked(index, type) {
            if (type == 1) {
                this.emojis.forEach(e => {
                    e.checked = false
                })
                this.emojis[index].checked = true
            } else {
                this.emojis2.forEach(e => {
                    e.checked = false
                })
                this.emojis2[index].checked = true
            }
        },
        closeCard() {
            this.hideCard = true
            setTimeout(() => {
                 setTimeout(() => {
                            this.$router.push("https://app.salonfaster.com")
                        }, 600)
                /* window.close()
                close(); */
            }, 600)
        },

    },
}
</script>


<style>
.form-body {
    transition: all .1s ease;
    transform-origin: top;
}

.form-body.hidden {
    transform: scaleY(0);
    overflow: hidden;
    height: 60px;
}

.btn {
    transition: all .4s ease-in-out;
}

.btn:hover {
    color: white;
}

.btn-active {
    -webkit-animation: mymove .5s;
    /* Safari 4.0 - 8.0 */
    animation: mymove .5s;
}

@keyframes mymove {
    0% {
        scale: 1;
    }
    30% {
        scale: .4;
    }
    100% {
        scale: 1;
        background-color: var(--green);
    }
}

b {
    font-weight: 500;
}

.shadow {
    transition: .1s ease-in-out;
}

.shadow.active {
    box-shadow: 0 0 2px .8rem #EBEBF3 !important;
    /* outline: 10px solid #EBEBF3 !important; */
    border-radius: 30px !important;
}

.spin {
    -webkit-animation: spin 1s ease-in-out infinite;
    -moz-animation: spin 1s ease-in-out infinite;
    animation: spin 1s ease-in-out infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader {
    transform: translateX(-50%) translateY(-50%);
    width: 20px;
    height: 20px;
    margin: auto;
}

.loader .circle {
    position: absolute;
    width: 38px;
    height: 38px;
    opacity: 0;
    transform: rotate(225deg);
    animation-iteration-count: infinite;
    animation-name: orbit;
    animation-duration: 3.5s;
}

.loader .circle:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: #fff;
    /* box-shadow: 0 0 9px rgba(255, 255, 255, .7); */
}

.loader .circle:nth-child(2) {
    animation-delay: 240ms;
}

.loader .circle:nth-child(3) {
    animation-delay: 480ms;
}

.loader .circle:nth-child(4) {
    animation-delay: 720ms;
}

.loader .circle:nth-child(5) {
    animation-delay: 960ms;
}

.loader .bg {
    position: absolute;
    width: 70px;
    height: 70px;
    margin-left: -16px;
    margin-top: -16px;
    border-radius: 13px;
}

@keyframes orbit {
    0% {
        transform: rotate(225deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }
    7% {
        transform: rotate(345deg);
        animation-timing-function: linear;
    }
    30% {
        transform: rotate(455deg);
        animation-timing-function: ease-in-out;
    }
    39% {
        transform: rotate(690deg);
        animation-timing-function: linear;
    }
    70% {
        transform: rotate(815deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }
    75% {
        transform: rotate(945deg);
        animation-timing-function: ease-out;
    }
    76% {
        transform: rotate(945deg);
        opacity: 0;
    }
    100% {
        transform: rotate(945deg);
        opacity: 0;
    }
}
</style>
